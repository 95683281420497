
import axios from "axios";
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
// import { getMovieDetails } from "@/api/detail";
import movieList from "@/mock/movie";

export default defineComponent({
  name: "Detail",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const id = router.currentRoute.value.params.id;
    const str = localStorage.getItem(id.toString()) || "";
    let detail: any = reactive({});
    const getList = () => {
      movieList.map((movieItem: any) => {
        axios.get(`/data/${movieItem}.json`).then((res) => {
          let { data } = res;
          data.map((item: any) => {
            if (item.id === id) {
              detail.id = item.id;
              detail.title = item.title;
              detail.url = item.url;
              detail.desc = item.desc;
              detail.summary = item.summary;
              detail.link = item.link;
              detail.img = item.img;
              detail.date = item.date;
              detail.translation = item.translation;
              detail.name = item.name;
              detail.year = item.year;
              detail.areas = item.areas;
              detail.category = item.category;
              detail.language = item.language;
              detail.caption = item.caption;
              detail.release = item.release;
              detail.imdb = item.imdb;
              detail.imdb_user = item.imdb_user;
              detail.douban = item.douban;
              detail.douban_user = item.douban_user;
              detail.time = item.time;
              detail.director = item.director;
              detail.writers = item.writers;
              detail.actor = item.actor;
              detail.starring = item.starring;
              detail.tag = item.tag;
              detail.introduction = item.introduction;
              localStorage.setItem(id.toString(), JSON.stringify(detail));
              return;
            }
          });
        });
      });
      // detail = dataSource.find((n: any) => n.id === id);
    };

    const data = [{}];
    if (str) {
      detail = JSON.parse(str);
    } else {
      getList();
    }
    store.commit("SET_DATA", detail);

    const dict = reactive({
      date: "发布时间",
      translation: "译名",
      name: "片名",
      year: "年代",
      areas: "产地",
      category: "类别",
      language: "语言",
      caption: "字幕",
      release: "上映日期",
      imdb: "IMDb评分",
      douban: "豆瓣评分",
      time: "片长",
      director: "导演",
      writers: "编剧",
      actor: "演员",
      starring: "演员",
      tag: "标签",
      introduction: "简介",
    });

    // const getDetail = async () => {
    //   const { data } = await getMovieDetails(id);

    //   detail = data.list;
    //   console.log(detail);
    //   return data;
    // };

    return {
      detail,
      dict,
    };
  },
});
